import * as React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import Header from "../components/header"
import FixedFooter from "../components/footer"

// styles
const headingStyles = {
    marginTop: 140,
    marginBottom: 100,
}

// markup
const AboutPage = () => {
  return (
    <main>
      <title>About Fieldstone Farm</title>
      <Header></Header>
      <Container style={headingStyles}>
        <Row>
            <div className="col-sm-8">
            <h2>Welcome!</h2>
            <p>
                Fieldstone Farm is a country retreat offering luxurious accommodations and exceptional venues located in historic Hillsboro, Virginia. 
            </p>
            <p>
            The moment you step into the inn you realize you are in one of America's treasured historic homes. Wide plank flooring, ceiling beams, the crackling 
            of fireplaces, extensive outdoor terraces and gardens and inside stone and plaster walls with careful attention to detail and color provide the backdrop 
            for furnishings, meeting rooms, and sleeping rooms selected for comfort.
            </p>
            <p>
                Built in 1770 this Quaker built stone manor house is now a country spot for executive retreats and meetings, and intimate weddings and events. 
            </p>
            <p>
                Fieldstone Farm sits in the historic village of Hillsboro, Virginia, the heart of the Hillsboro Wine Region, on 30 acres nestled in "The Gap" between 
                Short Hill Mountains with views of the Blue Ridge.
            </p>
            <p>
                Outdoors, guests enjoy a heated swimming pool with commanding views of the mountains, strolling through native plant gardens, herb and vegetable 
                gardens, a wildflower meadow, and hiking the paths in the woodland area.
            </p>
            <p>
                Inside, enjoy the timeworn beauty of the antiques and time-honored treasures of this country retreat. Warm woods, muted colors, and a graceful 
                pairing of colonial and refined furniture enhance and preserve the history of this Southern inn while fostering an air of relaxed elegance.
            </p>
            <p>
                Fieldstone Farm partners with local creative and sustainable businesses to meet all of your event needs. Special packages can be arranged to 
                include private catered lunch and dinners.
                <ul>
                    <li><a href="https://www.hillsboroughwine.com" runtime_url="https://www.hillsboroughwine.com" target="_blank" rel="noreferrer">Hillsborough Vineyards</a></li>
                    <li><a href="https://www.thebarnsathamiltonstation.com" runtime_url="https://www.thebarnsathamiltonstation.com" target="_blank" rel="noreferrer">The Barns of Hamilton Station Vineyard</a></li>
                    <li><a href="https://www.northgatevineyard.mobi" runtime_url="https://www.northgatevineyard.mobi" target="_blank" rel="noreferrer">North Gate Vineyard</a></li>
                    <li>Chef Terri Driscoll's <a href="http://itsapeaceofcakellc.com" runtime_url="http://itsapeaceofcakellc.com" target="_blank" rel="noreferrer">Its a Piece of Cake</a></li>
                    <li>Dale Long's <a href="https://www.food4thoughtcatering.info" runtime_url="https://www.food4thoughtcatering.info" target="_blank" rel="noreferrer">Food4Thought Catering</a></li>
                    <li><a href="https://www.magnoliasmill.com" runtime_url="https://www.magnoliasmill.com" target="_blank" rel="noreferrer">Magnolias at the Mill</a></li>
                    <li><a href="https://www.doukeniewinery.com" runtime_url="https://www.doukeniewinery.com" target="_blank" rel="noreferrer">Doukenie Winery</a></li>
                    <li><a href="https://868estatevineyards.com" runtime_url="https://868estatevineyards.com" target="_blank" rel="noreferrer">868 Estate Vineyards</a></li>
                    <li><a href="https://www.grandalerestaurant.com" runtime_url="https://www.grandalerestaurant.com" target="_blank" rel="noreferrer">Grandale Restaurant</a></li>
                    <li><a href="http://www.markettablebistro.com" runtime_url="http://www.markettablebistro.com" target="_blank" rel="noreferrer">Market Table Bistro</a></li>
                </ul>
            </p>
            </div>
            <div className="col-sm-4">
                <Row className="align-items-center">
                    <Col className="d-flex justify-content-center">
                    </Col>
                </Row>                
            </div>            
        </Row>        
      </Container>
      <FixedFooter/>      
    </main>
  )
}

export default AboutPage